@tailwind base;
@tailwind components;
@tailwind utilities;

// SCSS Variables
$primary-color: #3b82f6;
$secondary-color: #6b7280;
$breakpoint-md: 768px;
$navbar-height: 4rem;

// SCSS Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-md - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@layer base {
  html {
    @apply antialiased;
  }

  body {
    @apply bg-gray-50 text-gray-900;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    // Add padding for fixed navbar
    padding-top: $navbar-height;
    
    @include mobile {
      overflow-x: hidden;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@layer components {
  .card {
    @apply bg-white rounded-lg shadow-md p-4 md:p-6;
  }
  
  .btn {
    @apply px-3 py-2 md:px-4 md:py-2 rounded-md font-medium transition-colors duration-200;
    
    &-primary {
      @apply bg-blue-600 text-white hover:bg-blue-700;
    }
    
    &-secondary {
      @apply bg-gray-200 text-gray-800 hover:bg-gray-300;
    }
  }
  
  .input {
    @apply w-full px-3 py-2 md:px-4 md:py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500;
  }
}

// Custom SCSS styles
.container {
  @include flex-center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  
  @include mobile {
    padding: 0.75rem;
  }
}

// Grid system
.grid-cols-responsive {
  @apply grid gap-4 md:gap-6;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
}

// Form elements
.form-group {
  @apply mb-4;
  
  label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }
}

// Typography
.heading {
  @apply text-xl md:text-2xl font-bold;
  
  &-lg {
    @apply text-2xl md:text-3xl font-bold;
  }
  
  &-sm {
    @apply text-lg md:text-xl font-bold;
  }
}

// Spacing utilities
.section {
  @apply my-4 md:my-6;
}

.section-lg {
  @apply my-6 md:my-8;
}
